import React, { useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { Button, LinkButton } from '@/atoms/Button'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { AsDiv, TitleXS } from '@/atoms/Text'
import { EmbeddedVideo } from '@/molecules/EmbeddedVideo'
import { Modal } from '@/molecules/Modal'
import { useGuildMember } from '@/services/GuildUserService'
import { FeaturedProjectContentCollection, Maybe, ProjectTheme } from '@/types/codegen-contentful'
import { buildAnalyticsLink } from '@/utils/AnalyticsLinkBuilder'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

interface FeaturedProjectTicketsButtonGroupProps {
  campaign?: Maybe<string>
  contentCollection?: Maybe<FeaturedProjectContentCollection>
  href?: Maybe<string>
  guildMemberHref?: Maybe<string>
  sneakPeekUrl?: Maybe<string>
  projectTheme?: Maybe<ProjectTheme>
}

export const FeaturedProjectTicketsButtonGroup: React.FC<FeaturedProjectTicketsButtonGroupProps> = ({
  campaign,
  contentCollection,
  href,
  sneakPeekUrl,
  projectTheme,
}) => {
  const { t } = useTranslate('common')
  const track = useSafeTrack()
  const { asPath } = useRouter()
  const { isGuildMember } = useGuildMember()
  const [showModal, setShowModal] = useState(false)
  const duration = projectTheme?.sneakPeekDuration ?? null
  const analyticsLink = buildAnalyticsLink({
    href: href as string,
    path: asPath,
    campaign: campaign as string,
    linkContext: 'featured-section',
    source: 'featured-section',
  })
  const analyticsSneakPeekLink = buildAnalyticsLink({
    href: sneakPeekUrl as string,
    path: asPath,
    campaign: campaign as string,
    linkContext: 'featured-section',
    source: 'featured-section',
  })

  const video = useMemo(() => {
    const content = contentCollection?.items?.[0]
    return {
      title: content?.title ?? 'Trailer',
      url: content?.source ?? '',
    }
  }, [contentCollection?.items])

  const handleLinkClick = () => {
    track('Featured Project Link Clicked', { href })
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    setShowModal(true)
  }

  const showVideo = Boolean(video?.url || sneakPeekUrl)
  const showSneakPeek = !isGuildMember && sneakPeekUrl

  return (
    <div className="flex w-full flex-col justify-center gap-4 md:flex-row">
      <LinkButton
        href={analyticsLink}
        className="h-12 w-full min-w-[220px] justify-center space-x-2 md:w-fit"
        onClick={handleLinkClick}
        variant="white"
      >
        <TicketIcon color="gray-950" />
        <TitleXS as={AsDiv}>{t('getShowtimes', 'Get Showtimes')}</TitleXS>
      </LinkButton>
      <When condition={showVideo}>
        <When condition={showSneakPeek}>
          <LinkButton
            href={analyticsSneakPeekLink}
            className="h-12 w-full min-w-[220px] justify-center space-x-2 md:w-fit"
            variant="white"
            onClick={handleLinkClick}
            outline
          >
            <TitleXS>
              {duration
                ? t('watchFirstMinutes', `Watch The First {{ minutes }} Minutes`, {
                    minutes: duration,
                  })
                : t('watchSneakPeek', 'Watch Sneak Peek')}
            </TitleXS>
          </LinkButton>
        </When>
        <When condition={video?.url && !showSneakPeek}>
          <Button
            className="h-12 w-full min-w-[220px] justify-center space-x-2 md:w-fit"
            variant="white"
            onClick={handleClick}
            outline
          >
            <TitleXS>{t('watchTrailer', 'Watch Trailer')}</TitleXS>
          </Button>
        </When>
      </When>
      <Modal
        className="z-[99999]"
        panelClassesOverride="inline-block w-3/4 xl:w-full max-w-7xl align-middle shadow-xl rounded-2xl transition-all transform overflow-hidden"
        childWrapperClassName="mt-0"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <EmbeddedVideo className="w-full rounded-2xl" {...video} />
      </Modal>
    </div>
  )
}
