import React, { FC } from 'react'
import { useQuery } from '@apollo/client'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { GuildAccessPill } from 'src/molecules/GuildAccessPill'
import { PlayButtonThumbnailOverlay } from '@/atoms/PlayButtonThumbnailOverlay'
import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import { paths } from '@/constants'
import { LandscapeRowCollection, LandscapeTileCard } from '@/molecules/LandscapeRowCollection'
import { ProgressOverlay } from '@/molecules/MediaCard/ProgressOverlay'
import { PlaceholderLandscapeCollection } from '@/organisms/PlaceholderLandscapeCollection'
import { useGuildMember } from '@/services/GuildUserService'
import { ListContentItem } from '@/services/ListContentService/ListContentService'
import { GET_LIST_CONTENT_QUERY } from '@/services/ListContentService/queries'
import { ProjectType } from '@/services/ProjectsService'
import { LinkViewModel } from '@/services/RenderService'
import { GetListContentQuery } from '@/types/codegen-federation'
import { buildEpisodeTitle, buildLinkToEpisode } from '@/utils/EpisodeUtil'
import { AnalyticsEvent, useSafeTrack } from '@/utils/analytics'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'

interface Props {
  title: string
  testId?: string
  enableWatchProgress?: boolean
}
export const GuildEarlyAccessCollection: FC<Props> = ({ testId, title }) => {
  const track = useSafeTrack()
  const { t } = useTranslate('watch')
  const { isGuildMember } = useGuildMember()

  const { data: contentData, loading } = useQuery<GetListContentQuery>(GET_LIST_CONTENT_QUERY, {
    variables: { contentStates: ['EARLY_ACCESS'] },
    errorPolicy: 'all',
  })
  const listContentItems = [...(contentData?.listContent || [])]
    .filter((item) => item?.earlyAccessDate)
    .sort((a, b) => {
      if (a?.name?.toLowerCase() === 'movie' && b?.name?.toLowerCase() !== 'movie') {
        return -1
      }
      if (b?.name?.toLowerCase() === 'movie' && a?.name?.toLowerCase() !== 'movie') {
        return 1
      }
      return 0
    })

  if (loading) {
    return <PlaceholderLandscapeCollection />
  }

  return (
    <LandscapeRowCollection testId={testId} title={title}>
      {listContentItems?.map((item) => {
        if (!item) return null

        const linkModel = buildLinkModel(item)
        if (!linkModel) return null

        const subtitle = getSubTitle(item, t) ?? ''
        const title = item.subtitle ?? ''
        const duration = getDuration(item)

        const progress = ((item?.watchPosition?.position ?? 0) / (item?.duration ?? 0)) * 100

        const handleTracking = () => {
          if (item?.unavailableReason && item?.earlyAccessDate) {
            track('Rail Guild Thumbnail Clicked')
          } else {
            track('Rail Thumbnail Clicked')
          }
        }

        return (
          <LazyHydrate key={item?.guid} whenVisible>
            <>
              <LandscapeTileCard className="mb-2 mr-4" linkModel={linkModel} onClick={handleTracking}>
                <When condition={Boolean(progress)}>
                  <ProgressOverlay progress={progress} />
                </When>
                <div className="absolute inset-0">
                  <GuildAccessPill
                    isGuildMember={isGuildMember}
                    earlyAccessDate={item?.earlyAccessDate ? new Date(item.earlyAccessDate) : undefined}
                    unavailableReason={item.unavailableReason}
                  />
                </div>
                {!item.unavailableReason && <PlayButtonThumbnailOverlay />}
              </LandscapeTileCard>
              <TitleAndSubtitleDisplay className="mr-4" title={subtitle} subtitle={title} duration={duration} />
            </>
          </LazyHydrate>
        )
      })}
    </LandscapeRowCollection>
  )
}

const buildLinkModel = (item: ListContentItem): LinkViewModel | null => {
  if (!item?.season?.project?.slug) return null
  if (!item.guid) return null
  if (!item?.season?.project?.public) return null

  const linkUrl =
    buildLinkToEpisode({
      projectSlug: item?.season?.project?.slug,
      episodeNumber: item?.episodeNumber ?? 1,
      guid: item?.guid,
      seasonNumber: item.season.seasonNumber ?? 1,
      subtitle: item.subtitle ?? '',
    }) ?? `/${paths.watch.index}/${item?.season?.project?.slug}`

  const title = buildEpisodeTitle({
    title: item.subtitle as string,
    projectType: item.season.project.projectType as ProjectType,
    seasonNumber: item.season?.seasonNumber || 0,
    episodeNumber: item.episodeNumber || 0,
  })

  return {
    alt: title ? `Link to show ${title}` : 'Link to content',
    label: title,
    imageUrl: item.posterLandscapeCloudinaryPath,
    linkUrl,
    track: {
      eventName: 'Content Link Clicked' as AnalyticsEvent,
      payload: {
        linkUrl,
        projectSlug: item?.season?.project?.slug,
        episodeNumber: item?.episodeNumber ?? 1,
        guid: item?.guid,
        seasonNumber: item.season.seasonNumber ?? 1,
      },
    },
  }
}

function getSubTitle(item: ListContentItem, t: TranslateFunction) {
  if (!item) return null

  if (item?.name?.toLowerCase() === 'minisode') {
    return t('minisode', 'Minisode')
  } else if ('series' === item?.season?.project?.projectType) {
    return t('seasonEpisodeNumber', 'S{{seasonNumber}}:E{{episodeNumber}} {{projectName}}', {
      episodeNumber: item.episodeNumber,
      seasonNumber: item.season.seasonNumber,
      projectName: item.season.project.name ?? '',
    })
  } else if ('movie' === item?.season?.project?.projectType) {
    return t('film', 'Film')
  } else if ('podcast' === item?.season?.project?.projectType) {
    return t('podcast', 'Podcast')
  } else {
    return null
  }
}

function getDuration(item: ListContentItem) {
  return item?.season?.project?.projectType === 'movie' && Boolean(item.source?.duration) ? item.source?.duration : null
}
