import React from 'react'
import { useUserRegion } from '@/services/UserRegionService'
import { ReactFCC } from '@/types/react'
import { DEFAULT_REGION } from '@/utils/location'

interface RegionContextProp {
  children: React.ReactNode
}

interface RegionContextType {
  region: string
  loading: boolean
}

const RegionContext = React.createContext<RegionContextType>({
  region: DEFAULT_REGION,
  loading: true,
})

export const RegionContextProvider: ReactFCC<RegionContextProp> = ({ children }) => {
  const value = useUserRegion()

  return <RegionContext.Provider value={value}>{children}</RegionContext.Provider>
}

export function useRegionContext() {
  return React.useContext(RegionContext)
}
