import React from 'react'
import LazyHydrate from 'react-lazy-hydration'
import { PageDataContext } from '@/services/RenderService'
import { FranchiseComponentDisplay } from './FranchiseComponentDisplay'

export interface FranchiseComponentSectionProps {
  featuredProjectId: string
  pageDataContext: PageDataContext
}
export const FranchiseComponentSection: React.FC<FranchiseComponentSectionProps> = ({
  featuredProjectId,
  pageDataContext,
}) => {
  const { franchise, franchiseComponent } = pageDataContext

  return (
    <section id="franchise" data-testid={featuredProjectId}>
      <LazyHydrate whenVisible>
        <>
          {franchise && franchiseComponent && (
            <FranchiseComponentDisplay franchise={franchise} franchiseComponent={franchiseComponent} />
          )}
        </>
      </LazyHydrate>
    </section>
  )
}
