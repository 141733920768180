import { FranchiseExtended } from '@/services/FranchiseService'
import { FranchiseComponent } from '@/types/codegen-contentful'
import { FranchiseComponentBackground } from './FranchiseComponentBackground'
import { FranchiseComponentCallToAction } from './FranchiseComponentCallToAction'
import { assignBackgroundImage } from './FranchiseComponentUtils'

export interface FranchiseComponentDisplayProps {
  franchise: FranchiseExtended
  franchiseComponent: FranchiseComponent
}

export const FranchiseComponentDisplay: React.FC<FranchiseComponentDisplayProps> = ({
  franchise,
  franchiseComponent,
}) => {
  const mobileBackgroundUrl = franchise?.backdropPortrait?.[0]?.cloudinaryPath
  const desktopBackgroundUrl = franchise?.backdropLandscape?.[0]?.cloudinaryPath

  const backgrounds = {
    mobile: mobileBackgroundUrl as string,
    tablet: mobileBackgroundUrl as string,
    desktop: desktopBackgroundUrl as string,
    ultra: desktopBackgroundUrl as string,
    gradient: true,
  }

  const backgroundImage = assignBackgroundImage(backgrounds)

  return (
    <div className="relative h-fit bg-core-gray-950 lg:h-[90vh] lg:min-h-[1000px] shortDesktop:min-h-[800px]">
      <div className="relative h-full w-full md:flex md:items-center md:justify-center">
        <FranchiseComponentBackground backgroundImage={backgroundImage} />
        <FranchiseComponentCallToAction franchise={franchise} franchiseComponent={franchiseComponent} />
      </div>
    </div>
  )
}
