import React, { useCallback } from 'react'
import { ResponsiveObject } from 'react-slick'
import { AsH1, TitleLG } from '@/atoms/Text'
import { HorizontalItemScroller } from '@/molecules/HorizontalItemScroller'
import { LinkViewModel } from '@/services/RenderService'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'
import { PortraitTileCard } from '../PortraitRowCollection'

export interface Props {
  title?: string
  contentLinks?: LinkViewModel[]
  separateMoviesAndSeriesExperiment?: boolean
}

export const MoviesAndSeriesCollection: React.FC<Props> = React.memo(({ contentLinks }) => {
  const track = useSafeTrack()
  const { t } = useTranslate('watch')
  const sorted = contentLinks?.sort((a, b) => (a?.sortOrder || 0) - (b?.sortOrder || 0)) || []

  const responsiveSlick: ResponsiveObject[] = [
    {
      breakpoint: 1536,
      settings: {
        slidesToScroll: 6,
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToScroll: 5,
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToScroll: 4,
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToScroll: 3,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToScroll: 2,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToScroll: 2,
        slidesToShow: 2,
      },
    },
  ]

  const trackClick = useCallback(
    (contentLink: LinkViewModel) => {
      if (!contentLink.track) return
      track(contentLink.track?.eventName, contentLink.track?.payload)
    },
    [track],
  )

  return sorted?.length <= 1 ? null : (
    <>
      <div data-testid="movies-and-series-collection">
        <header>
          <TitleLG weight="bold" as={AsH1} data-testid="project-tiles-header" className="mb-3 md:mb-4">
            {t('movies', 'Movies')}
          </TitleLG>
        </header>
        <HorizontalItemScroller slidesToScroll={6} slidesToShow={6} responsive={responsiveSlick}>
          {sorted
            ?.filter((contentLink) => contentLink?.imageUrl && contentLink?.projectType === 'movie')
            ?.map((contentLink) => {
              if (!contentLink?.portraitUrl) return null

              return (
                <PortraitTileCard
                  className="mb-2 mr-4"
                  key={contentLink.imageUrl}
                  linkModel={contentLink}
                  onClick={() => trackClick(contentLink)}
                  defaultPortrait
                />
              )
            })}
        </HorizontalItemScroller>
        <header>
          <TitleLG weight="bold" as={AsH1} data-testid="project-tiles-header" className="mb-3 mt-12 md:mb-4">
            {t('series', 'Series')}
          </TitleLG>
        </header>
        <HorizontalItemScroller slidesToScroll={6} slidesToShow={6} responsive={responsiveSlick}>
          {sorted
            ?.filter((contentLink) => contentLink?.imageUrl && contentLink?.projectType !== 'movie')
            ?.map((contentLink) => {
              if (!contentLink?.portraitUrl) return null

              return (
                <PortraitTileCard
                  className="mb-2 mr-4"
                  key={contentLink.imageUrl}
                  linkModel={contentLink}
                  onClick={() => trackClick(contentLink)}
                  defaultPortrait
                />
              )
            })}
        </HorizontalItemScroller>
      </div>
    </>
  )
})

MoviesAndSeriesCollection.displayName = 'React.memo(MoviesAndSeriesCollection)'
