import React from 'react'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { BackgroundImage } from './FranchiseComponentUtils'

interface FranchiseComponentBackgroundProps {
  backgroundImage: BackgroundImage
}

export const FranchiseComponentBackground: React.FC<FranchiseComponentBackgroundProps> = ({ backgroundImage }) => {
  return (
    <>
      <div className="relative hidden h-full w-full md:absolute md:inset-0 md:flex">
        <ResponsiveBackgroundImage {...backgroundImage} backgroundPosition="bg-right-top" />
      </div>
      <div className="aspect-h-10 aspect-w-16 relative w-full md:hidden">
        <ResponsiveBackgroundImage {...backgroundImage} backgroundPosition="bg-left-top" />
      </div>
      {/* gradient for background image */}
      {backgroundImage.gradient && (
        <div className="absolute left-0 top-0 hidden h-full w-full bg-gradient-to-t from-gray-950 md:flex" />
      )}
      {backgroundImage.gradient && (
        <div className="aspect-h-10 aspect-w-16 absolute left-0 top-0 flex w-full bg-gradient-to-t from-gray-950 md:hidden" />
      )}
    </>
  )
}
