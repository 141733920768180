export type BackgroundImage = {
  gradient: boolean
  gravity: string
  responsiveAR: {
    sm: string
    md: string
    lg: string
    xl: string
    '2xl': string
  }
  src: string
  srcUrls: {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
    '2xl': string
  }
}

interface AssignBackgroundImageProps {
  gradient?: boolean
  gravity?: string
  mobile: string
  desktop: string
  tablet: string
  ultra: string
}

export function assignBackgroundImage({
  desktop,
  gradient = true,
  gravity = 'g_north',
}: AssignBackgroundImageProps): BackgroundImage {
  return {
    gradient,
    gravity,
    responsiveAR: {
      sm: '16:9',
      md: '16:9',
      lg: '16:9',
      xl: '16:9',
      '2xl': '16:9',
    },
    src: desktop,
    srcUrls: {
      xs: desktop,
      sm: desktop,
      md: desktop,
      lg: desktop,
      xl: desktop,
      '2xl': desktop,
    },
  }
}
