import React from 'react'
import kebabCase from 'lodash/kebabCase'
import { ResponsiveObject } from 'react-slick'
import { AsH1, TitleLG } from '@/atoms/Text'
import { HorizontalItemScroller } from '@/molecules/HorizontalItemScroller'
import { LandscapeTileCard } from '@/molecules/LandscapeRowCollection/LandscapeTileCard'
import { LinkViewModel } from '@/services/RenderService'

interface Props extends React.HTMLAttributes<HTMLElement> {
  id?: string
  contentLinks?: LinkViewModel[]
  title?: string
  testId?: string
  children?: React.ReactNode
  responsiveSlick?: ResponsiveObject[] | undefined
  slidesToShow?: number | undefined
  slidesToScroll?: number | undefined
}

export const LandscapeRowCollection: React.FC<Props> = ({
  id,
  contentLinks,
  title,
  testId = 'landscape-row-collection',
  children,
  responsiveSlick,
  slidesToScroll,
  slidesToShow,
}) => {
  return (
    <div id={id ? id : title ? kebabCase(title) : undefined} data-testid={testId}>
      {title && (
        <header>
          <TitleLG weight="bold" as={AsH1} className="mb-3 md:mb-4">
            {title}
          </TitleLG>
        </header>
      )}

      <HorizontalItemScroller responsive={responsiveSlick} slidesToScroll={slidesToScroll} slidesToShow={slidesToShow}>
        {children
          ? children
          : contentLinks
          ? contentLinks?.map((contentLink) => {
              return <LandscapeTileCard className="mb-2 mr-4" key={contentLink.imageUrl} linkModel={contentLink} />
            })
          : null}
      </HorizontalItemScroller>
    </div>
  )
}
