import { FC } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { useRegionContext } from '@/contexts/RegionContext'
import { Collection } from '@/molecules/Collection'
import { FeaturedProjectSection } from '@/organisms/FeaturedProjectSection'
import { FranchiseComponentSection } from '@/organisms/FranchiseComponentSection'
import { InvestmentBanner } from '@/organisms/InvestmentBanner'
import { isInvestmentBanner } from '@/organisms/InvestmentBanner/InvestmentBanner'
import { isCallToAction, CallToAction } from '@/page/CallToAction'
import { CollectionDisplay, isCollectionDisplay } from '@/page/CollectionDisplay'
import { Header, isHeader } from '@/page/Header'
import { isSpacer, Spacer } from '@/page/Spacer'
import { isTextBlock, TextBlock } from '@/page/TextBlock'
import {
  isCollectionModel,
  isFeaturedProject,
  isUltrawideBannerGroupModel,
  PageDataContext,
  isFranchiseComponent,
} from '@/services/RenderService'
import {
  ContentExperimentVariantCollection,
  InvestmentBanner as InvestmentBannerContent,
  Maybe,
  PageContentItem,
  Spacer as SpacerType,
} from '@/types/codegen-contentful'
import { BannerCarousel } from '@/views/WatchLandingView/BannerCarousel'

interface Props {
  content: Maybe<PageContentItem | ContentExperimentVariantCollection>
  pageDataContext: PageDataContext
}

export const ContentItem: FC<Props> = ({ content, pageDataContext }) => {
  const { asPath } = useRouter()
  const { region } = useRegionContext()

  if (!content) return null

  const contentItem = renderContentItem(content, pageDataContext, region)
  return wrapContentItem(contentItem, content, pageDataContext, asPath)
}

const renderContentItem = (
  content: Maybe<PageContentItem | ContentExperimentVariantCollection>,
  pageDataContext: PageDataContext,
  region: string,
) => {
  if (content && 'region' in content && content?.region && !content.region.includes(region)) {
    return null
  } else if (isTextBlock(content)) {
    return <TextBlock textBlock={content} />
  } else if (isHeader(content)) {
    return <Header header={content} />
  } else if (isCollectionModel(content)) {
    return <Collection collection={content} pageDataContext={pageDataContext} />
  } else if (isCollectionDisplay(content)) {
    return <CollectionDisplay collectionDisplay={content} pageDataContext={pageDataContext} />
  } else if (isCallToAction(content)) {
    return <CallToAction callToAction={content} />
  } else if (isSpacer(content)) {
    return <Spacer spacer={content as SpacerType} />
  } else if (isUltrawideBannerGroupModel(content)) {
    const groupId = content?.sys.id
    return groupId ? <BannerCarousel groupId={groupId} preview={pageDataContext.preview} /> : null
  } else if (isFeaturedProject(content)) {
    return (
      <FeaturedProjectSection
        testId={content.sys.id}
        featuredProjectId={content.sys.id}
        preview={pageDataContext.preview}
      />
    )
  } else if (isFranchiseComponent(content)) {
    return <FranchiseComponentSection featuredProjectId={content.sys.id} pageDataContext={pageDataContext} />
  } else if (isInvestmentBanner(content)) {
    const typedContent = content as InvestmentBannerContent
    return (
      // align the left side with the rails
      <InvestmentBanner {...typedContent} className="sm:ml-8 md:ml-12 xl:ml-16" />
    )
  }
  return null
}

const wrapContentItem = (
  contentItem: JSX.Element | null,
  content: Maybe<PageContentItem | ContentExperimentVariantCollection>,
  pageDataContext: PageDataContext,
  asPath: string,
) => {
  if (!contentItem) return null

  if (
    (isUltrawideBannerGroupModel(content) || isTextBlock(content) || isCallToAction(content)) &&
    pageDataContext.contentItemConfig?.padding
  ) {
    return (
      <PaddedContainer className="overflow-x-hidden" fluid>
        {contentItem}
      </PaddedContainer>
    )
  } else if (isHeader(content)) {
    return (
      <PaddedContainer
        className={classNames('-mb-4 overflow-x-hidden', {
          '-mb-8': asPath.includes('watch'),
        })}
        fluid
      >
        {contentItem}
      </PaddedContainer>
    )
  } else {
    return <>{contentItem}</>
  }
}
